import React, { useState } from 'react';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import './templateRenderer.css';

type props = {
  innerHtml: string,
  article?: boolean,
  printable?: boolean
};

function TemplateRenderer({ innerHtml, article = false, printable = false }: props) {
  const [ref, setRef] = useState();
  return (
    <>
    <div className="teamplateHeader">
      <ReactToPrint
        trigger={() => (
          <Button>
            <PrintIcon style={{ marginRight: '0.5em' }}/>
            <FormattedMessage id="covid19.components.TemplateRenderer.print" /> 
          </Button>
        )}
        content={() => ref}
      />
    </div>
    <div ref={(r) => setRef(r)} className={`templateContainer ${article && "article"}`} dangerouslySetInnerHTML={{__html: innerHtml }}/>
    </>
  );
}

export default TemplateRenderer;
