import { postJson, config } from './utils';

export const subscribeEmail = (email: string) => {
  console.log('subscribing to', window.location.href, email);

  postJson(`${config.apiBaseUrl}/api/subscribe/covid19`, {
    sourceApp: "covid19.cdepnql.com",
    email: email,
    identifier: window.location.href
  });
};
