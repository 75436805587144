import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl';
import { getTemplate } from '../../../api/templates';
import { QuestionnaireItemProps } from './CurrentItem';
import Subscribe from '../../Subscribe/Subscribe';
import TemplateRenderer from '../../TemplateRenderer/TemplateRenderer';

type ResultProps = QuestionnaireItemProps & {
  classes: any,
  data: {
    content: string
  }
};

function Result({ data: { content }, classes, setCurrentItem } : ResultProps){
  const intl = useIntl();

  const localizedContent = intl.locale === 'en' ? content.replace('.html', '_en.html') : content;

  const [htmlContent, setHtmlContent] = useState('Chargement...');
  const [currentTemplate, setCurrentTemplate] = useState('');

  const loadNewTemplate = async (template: string) => {
    setHtmlContent(await getTemplate(template));
    setCurrentTemplate(template);
  }

  useEffect(() => {
    if (currentTemplate !== localizedContent) {
      console.log('Loading template', localizedContent);
      loadNewTemplate(localizedContent);
    }
  });

  return (
    <>
      <TemplateRenderer article printable innerHtml={htmlContent}/>
      <Subscribe />
    </>
  );
}

export default Result;
