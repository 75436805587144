import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import './header.css';

function Header() {
  return (
    <Container id="header" maxWidth="lg">
      <Typography variant="h1" className="title">
        <img src="/logo-cdepnql-fr.svg" alt="CDEPNQL - FNQLEDC" height="80px" />
        <FormattedMessage id="covid19.components.header.title" />
      </Typography>
      <Typography className="intro">
        <FormattedMessage id="covid19.components.header.presentation" />
        <br/>
        <br/>
        <FormattedMessage id="covid19.components.header.updateOften" />
      </Typography>
    </Container>
  );
}

export default Header;
