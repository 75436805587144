import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'qs';
//import ResponseSummary from '../ResponseSummary/ResponseSummary';
import CurrentItem from './items/CurrentItem';
import questionnaire from '../../questionnaires/questionnaire-et-contenu.json';
import { AnswerProps } from './items/Answer';
import QuestionnaireAction from './QuestionnaireAction';
import './questionnaire.css';


const isLoadingFromUrlQuery = (stateQueryObject: stateQueryObjectType, browserQueryObject: stateQueryObjectType) : boolean =>
  (Array.isArray(browserQueryObject.path) && stateQueryObject.path.length === 0);

const getPathInQuestionnaire = (browserQueryObject: stateQueryObjectType, questionnaire: object) : object => {
  let currentItem : any = questionnaire;
  browserQueryObject.path.forEach((item: any) => {
    currentItem = currentItem.answers.find((a: any) => a.answer_id === item.answer_id);
  });
  return currentItem;
}

type stateQueryObjectType = {
  path: object[]
};

const getInitialQueryObject = () : stateQueryObjectType => ({ path: [] });

function Questionnaire() {
  const [ currentItem, setCurrentItem ] = useState(questionnaire as any);
  const history = useHistory();
  const location = useLocation();
  const [stateQueryObject, setStateQueryObject] = useState(getInitialQueryObject());

  console.log('got location.search', location.search);
  const browserQueryObject = qs.parse(location.search.substr(1));

  if (isLoadingFromUrlQuery(stateQueryObject, browserQueryObject)) {
    console.log('browserQueryObject',browserQueryObject);
    setCurrentItem(getPathInQuestionnaire(browserQueryObject, questionnaire));
    setStateQueryObject(browserQueryObject);
  }

  const answerClicked = (answer: AnswerProps["data"]) => {
    stateQueryObject.path.push({ question_id: currentItem.question_id, answer_id: answer.answer_id });
    const newQueryString = qs.stringify(stateQueryObject);

    history.push(`/questionnaire/?${newQueryString}`);

    setCurrentItem(answer as any);
  }

  const restartQuestionnaire = () => {
    history.push('/questionnaire/');
    setCurrentItem(questionnaire);
    setStateQueryObject(getInitialQueryObject());
  };

  return (
      <Container maxWidth="lg">
        <Paper style={{ padding: '2em' }}>
          <div className="questionnaireActionBar">
            <QuestionnaireAction action={restartQuestionnaire} text="covid19.components.questionnaire.startOver" />
          </div>
          <CurrentItem setCurrentItem={answerClicked} data={currentItem} type={questionnaire.type} />
        </Paper>
      </Container>
  );
}

export default Questionnaire;
