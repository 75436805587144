import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import { useIntl } from 'react-intl';
import { getTemplate } from '../../../api/templates';
import TemplateRenderer from '../../TemplateRenderer/TemplateRenderer';
import { QuestionnaireItemProps } from './CurrentItem';
import Answer, { AnswerProps } from './Answer';

const styles = {
  questionContainer: {
    margin: '2em 0'
  },
  answer: {
    display: 'flex'
  }
};

type QuestionProps = QuestionnaireItemProps & {
  classes: any,
  data: {
    question: string,
    question_en: string,
    question_helper: string,
    question_helper_en: string,
    answers: AnswerProps["data"][]
  }
};

const StyledQuestion = withStyles(styles)(function Question({ data: { question, question_en, question_helper, question_helper_en, answers }, classes, setCurrentItem } : QuestionProps){
  const [questionHelperContent, setQuestionHelperContent] = useState("");

  const loadNewTemplate = async (template: string) => {
    setQuestionHelperContent(await getTemplate(template));
  }

  useEffect(() => { 
    const localizedQuestionHelper = intl.locale === 'en' ? question_helper_en : question_helper;
    if(question_helper) {
      loadNewTemplate(localizedQuestionHelper);
    }
  })

  const intl = useIntl();

  const localizedQuestion = intl.locale === 'en' ? question_en : question;

  return (
    <div className={classes.questionContainer}>
      <Typography variant="h4">{localizedQuestion}</Typography>
        {question_helper && (
          <TemplateRenderer innerHtml={questionHelperContent} />
        )}
        {answers.map(a => (
          <div key={a.answer} className={classes.answer}>
            <Answer data={a} onClick={() => setCurrentItem(a)} />
          </div>
        ))}
    </div>
  );
});

export default StyledQuestion;
