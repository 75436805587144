import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { FormattedMessage } from 'react-intl';
import LocalePicker from '../LocalePicker/LocalePicker';
import './Footer.css';

function Header() {
  return (
    <Paper id="footer">
      <div className="logo">
        <Link href="https://cdepnql.org" target="_blank" style={{ margin: '1em 0 0 13px' }} >
          <img src="/logo-cdepnql-fr.svg" alt="CDEPNQL - FNQLEDC" height="100px" />
          </Link>
      </div>
      <div className="contact">
        <List>
          <ListItem>
            <Typography>
              <FormattedMessage id="covid19.components.footer.contact" defaultMessage="Contact" /> :
              <Link href="mailto:info@cdepnql.org"> info@cdepnql.org</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>
              <FormattedMessage id="covid19.components.footer.moreInformation" /> :
              <Link href="https://cdepnql.org/boite_a_outils/covid-19-informations-aux-adec-et-aux-entreprises/" target="_blank"> cdepnql.org - COVID-19</Link>
            </Typography>
          </ListItem>
          <ListItem>
            <FormattedMessage id="covid19.components.footer.language" /> :
            <LocalePicker/>
          </ListItem>
        </List>
      </div>
      <div className="disclaimer">
        <Typography variant="caption">
          <FormattedMessage id="covid19.components.footer.disclaimer" />
        </Typography>
      </div>
    </Paper>
  );
}

export default Header;
