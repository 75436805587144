import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

export type QuestionnaireActionType = {
  action: (e:  any) => any,
  text: string
};

function QuestionnaireAction({ action, text } : QuestionnaireActionType) {
  return (
    <Button onClick={action} variant="outlined">
      <FormattedMessage id={text} />
    </Button>
  );
};

export default QuestionnaireAction;
