import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useIntl } from 'react-intl';
import { withStyles, createStyles } from '@material-ui/styles';

const styles = createStyles({
  container: {
    margin: '1em',
    textAlign: 'left',
    flexGrow: 1
  },
  card: {
    width: '100%',
    padding: '1em'
  }
});

export type AnswerProps = {
  data: {
    answer: string,
    answer_en: string,
    answer_id: string,
    question_id: string
  }
  classes: any,
  onClick: () => any
};

const StyledAnswer = withStyles(styles)(function Answer({ classes, data: { answer, answer_en, ...rest}, onClick } : AnswerProps){
  const intl = useIntl();

  const localizedAnswer = intl.locale === 'en' ? answer_en : answer;

  return (
    <ButtonBase className={classes.container} onClick={onClick} >
      <Card className={classes.card} >
        <Typography>{localizedAnswer}</Typography>
      </Card>
    </ButtonBase>
  );
});

export default StyledAnswer;
