export const config = {
  apiBaseUrl: 'https://api.entreprises.cdepnql.com'
};

export function getUrl(url: string): Promise<string> {
  return fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.text()
    })
}

export function postJson(url: string, data: object): Promise<string> {
  return fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.text()
    })
}
