import React, { useContext, ChangeEvent } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useIntl } from 'react-intl';
import { LocaleContext } from '../LocaleProvider/LocaleProvider';

function LocalePicker() {
  const localeDispatch = useContext(LocaleContext);
  const intl = useIntl();

  const setLocale = (event : ChangeEvent<{value: unknown}>) => {
    console.log('setting locale', event.target.value);
    localeDispatch(event.target.value);
  };
  return (
    <Select value={intl.locale} onChange={setLocale} style={{ marginLeft: '0.4em' }}>
      <MenuItem value="fr">Français</MenuItem>
      <MenuItem value="en">English</MenuItem>
    </Select>
  );
}

export default LocalePicker;

