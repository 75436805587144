import React from 'react';
import './App.css';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Questionnaire from './components/Questionnaire/Questionnaire';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/questionnaire/*">
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '100vh'
          }}>
            <Header />
            <Questionnaire />
            <Footer />
          </div>
        </Route>
        <Redirect to="/questionnaire/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
