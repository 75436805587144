import React, { ComponentType } from 'react';
import PropTypes from 'prop-types';
import Question from './Question';
import Result from './Result';

const getComponentForType = (type: string) : ComponentType<QuestionnaireItemProps> => {
  switch (type) {
    case 'question':
    case 'subquestion':
      return Question as unknown as ComponentType<QuestionnaireItemProps>;
    case 'result':
      return Result as unknown as ComponentType<QuestionnaireItemProps>;
    default:
      throw Error(`No component available in questionnaire for type ${type}`);
  }
};

export type QuestionnaireItemProps = {
  data: {
    type: string
  },
  setCurrentItem: (item: any) => void
}

//export class QuestionnaireItemBase<T extends QuestionnaireItemProps> extends Component<T> {}

function CurrentItem({ data, setCurrentItem }: QuestionnaireItemProps) {
  const ItemComponent = getComponentForType(data.type as string);
  return (
    <ItemComponent data={data} setCurrentItem={setCurrentItem}/>
  );
}

CurrentItem.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default CurrentItem;
