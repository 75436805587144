import React, {useState} from 'react'
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { subscribeEmail } from '../../api/api_entreprises';
import "./subscribe.css";

function Subscribe() {
  const [emailInputRef, setEmailInputRef] = useState();
  const [subscribtionSucceeded, setSubscribtionSucceeded] = useState();
  const [emailValidity, setEmailValidity] = useState(true);

  const subscribe = async () => {
    const email = emailInputRef.value;
    // eslint-disable-next-line no-control-regex
    if (!email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)) {
      setEmailValidity(false);
    } else {
      setEmailValidity(true);
      await subscribeEmail(email);
      emailInputRef.value = "";
      setSubscribtionSucceeded(true);
    }
  };

  return (
    <div className="subscribe_container">
      <Typography paragraph>
        <FormattedMessage id="covid19.components.Subscribe.title" />
      </Typography>
      <div className="inputContainer">
        <TextField error={!emailValidity} className="emailInput" inputRef={setEmailInputRef} name="email" variant="outlined" placeholder="courriel@example.com" size="small" />
          <Button variant="contained" color="secondary" onClick={subscribe} >
            <FormattedMessage id="covid19.components.Subscribe.subscribe" />
          </Button>
      </div>
      {subscribtionSucceeded &&
        <Typography style={{ color: "green" }}>
          <FormattedMessage id="covid19.components.Subscribe.success" />
        </Typography>
      }
    </div>
  );
}

export default Subscribe;
