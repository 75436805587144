import React, {useReducer}  from 'react';
import { IntlProvider } from 'react-intl';
import translations from '../../i18n';

type LocaleProviderProps = {
  children: React.ReactNode
};

export const LocaleContext = React.createContext(null as unknown as Function);

const localeReducer = (state: any, action: any) => action;

function LocaleProvider({ children }: LocaleProviderProps) {
  const [locale, dispatch] = useReducer(localeReducer, translations.DEFAULT_LOCALE);

  return (
    <IntlProvider
      locale={locale}
      key={locale}
      messages={(translations.messages as any)[locale] as any}>
        <LocaleContext.Provider value={dispatch}>
          {children}
        </LocaleContext.Provider>
    </IntlProvider>
  );
}

export default LocaleProvider;
